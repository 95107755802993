import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { Row, Col, Button } from "react-bootstrap"
import Seo from "../../components/seo";
import { Icon } from "@iconify/react";
import CancelTour from "../../components/popup/CancelTour"
import { agentUnavailable, tourList } from '../../components/Api/ListingApi'
import AvatarImage from "../../components/Avatar/avatarImage";
// import Loader from '../../components/Loader'
import ChatPopup from '../../components/popup/ChatPopup';
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import { useSelector } from "react-redux";
import BuyerScheduleCalender from '../../components/popup/buyerScheduleCalender'
import Moment from "moment-timezone";
import ListingAgentProfile from "../../components/popup/listingAgentProfile"
import ToastPopup from "../../components/popup/ToastPopup"
import SkeletonHorizondalCard from '../../components/card/skeletonHorizondalCard';
import { pageViewAnalytics, ChatData } from '../../common/GenericFunction';

export default function Tours() {
  //redux declaration //
  const userDetails = useSelector(state => state.user.userDetail);
  const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
  const userlocation = useSelector(state => state.user.userLocation);
  // variable declare //
  const array = ["1", "2", "3", "4"]
  //usestate declaration //
  const [message, setMessage] = useState(false)
  const [click, setClick] = useState(false);
  const [isdataFounded, setisDataFounded] = useState(false)
  const [loader, setLoader] = useState(false)
  const [userSuccess, setUserSucess] = useState(false)
  const [listing, setListing] = useState([])
  const [item, setItem] = useState({})
  const [modifyData, setModifyData] = useState({})
  const [success, setSucess] = useState(false);
  const [messages, setMessages] = useState("")
  const [successAgent, setSucessAgent] = useState(false);
  const [chatAgent, setChatAgent] = useState({})
  const [chatUser, setChatUser] = useState({})
  const [chatId, setChatId] = useState("")
  const [openChat, setOpenChat] = useState(false)
  const [isActivee, setIsActivee] = useState(false);
  const [indexx, setIndexx] = useState(0);
  const [date, setDate] = useState({})
  const [showAgent, setShowAgent] = useState(false);
  const [details, setDetails] = useState({})

  //Tour modify popup// 
  const toggleClick = () => setClick(p => !p);
  // Chat popup //
  const showMessage = () => {
    setMessage(true)
  }
  // cancel tour popup //
  const toggleUserSuccess = () => setUserSucess(p => !p)
  const toggleSuccess = () => setSucess(p => !p);

  //useEffect calling //
  useEffect(() => {
    tourListUser();
    pageViewAnalytics("User Tour", userDetails, userlocation)
  }, [])

  //toast popup //
  const toggleSuccessAgent = () => setSucessAgent(p => !p);

  //modify tour function //
  const handleModifyData = (item) => {
    setModifyData(item)
    toggleClick()
  }
  // Tour list Api integration //
  const tourListUser = async () => {
    let userId = localStorage.getItem('userId')
    let getParams = {
      user_id: userId
    }
    setLoader(true);
    tourList(getParams).then((res) => {
      // console.log("response tour", res.data.data)
      setLoader(false);
      setListing(res.data.data)
      let data = [...res.data.data]
      data.length !== 0 ? setisDataFounded(false) : setisDataFounded(true)
    }).catch((error) => {
      console.log(error)
      setLoader(false);
    })
  }
  // image null handle //
  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }
  //chat popup //
  const showChatDetail = () => {
    if (openChat === false) {
      setLoader(true);
    }
    else {
      setLoader(false);
    }
    setOpenChat(p => !p)
  }

  // chat function //
  const handleChat = async (id, name, lastName, image) => {
    let names = name
    await chattingUserData !== null && chattingUserData.disconnectUser()
    const apikey = process.env.STREAM_CHAT_APIKEY;
    const chatClient = StreamChat.getInstance(apikey);
    await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
    await chatClient.disconnectUser()
    setChatAgent({ id: id, name: names, image: image })
    setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
    setChatId(id + userDetails._id)
    showChatDetail()
    showMessage()
  }
  // card select function //
  const handleClick = (index, e) => {
    setIndexx(index)
    setIsActivee(true);
  }

  //Listing agent profile //
  const toggleAgent = ((item) => {
    setShowAgent(p => !p)
    setDetails(item)
  })

  // agent unavailable reach function //
  const handleAgentStatus = async (item) => {
    let agentId = item.agent_id._id
    let userId = localStorage.getItem("userId")
    let getParams = {
      agent_id: agentId,
      user_id: userId
    }
    await agentUnavailable(getParams).then(res => {
      setMessages("Agent has been notified. You will hear back from the Agent shortly")
      toggleSuccessAgent()
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <>
      <Layout>
        <Seo title="Tours" />
        <div>
          <ToastPopup success={successAgent} toggleSuccess={toggleSuccessAgent} messages={messages} />
          <CancelTour userSuccess={userSuccess} toggleUserSuccess={toggleUserSuccess} data={item} reload={tourListUser} success={success} toggleSuccess={toggleSuccess} messages={messages} />
          <ListingAgentProfile showAgent={showAgent} toggleAgent={toggleAgent} detail={details} />
          {click && <BuyerScheduleCalender click={click} toggleClick={toggleClick} details={modifyData}
            propertyId={""} isBuyer={false} reload={" "} refresh={tourListUser} detail={modifyData} />}
          <div style={{ margin: "0px 50px", padding: "15px 0px 80px 0px" }}>
            <div >
              <div className='row'>
                {loader ?
                  <div className={`${message === true ? 'col-md-12' : 'col-md-12'}`} >
                    {array.map((item) => (
                      <SkeletonHorizondalCard purpose={"tour"} />
                    ))}
                  </div> :
                  listing.map((item, index) => (
                    <div className={`${message === true ? 'col-md-12' : 'col-md-12'}`} key={index} >
                      <h6 style={{ fontFamily: "DejaVuSansBold", color: "black", marginTop: "20px" }}>
                        {Moment((item._id)).format("dddd, MMMM Do")}
                      </h6>
                      {item.groupdata.map((item, index) => (
                        <Row key={index}
                          onClick={(e) => { handleClick(index, date, e); setDate(item._id) }}
                          style={{
                            borderRadius: "20px",
                            boxShadow: "1px 2px 4px 2px #e4e5e7",
                            margin: "30px 0 0 0",
                            padding: "20px 10px",
                            background: "#fff",
                            border: (isActivee && date === item._id && indexx === index) ? "1px solid rgb(4, 144, 251)" : "1px solid white"
                          }}>
                          <Col lg="5">
                            <Row>
                              <Col lg="6">
                                {item.property_id !== null && (item.property_id.thumbnail_image !== null && item.property_id.thumbnail_image !== "" && item.property_id.thumbnail_image !== undefined) ? (item.property_id.thumbnail_image.includes('mls') ? <img src={item.property_id.thumbnail_image !== undefined && item.property_id.thumbnail_image !== null && hanldeImage(item.property_id.thumbnail_image)} id='sellertourimg' alt="EditGroup1 " style={{ borderRadius: "10px", height: "180px", width: "100%" }} /> :
                                  <img src={item.property_id !== null && item.property_id.thumbnail_image !== null && item.property_id.thumbnail_image !== undefined && hanldeImage(item.property_id.thumbnail_image)} alt="EditGroup1 " crossOrigin='anonymous' id='sellertourimg' style={{ borderRadius: "10px", height: "180px", width: "100%" }} />) :
                                  <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${item.property_id.address}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="image" id='sellertourimg' style={{ borderRadius: "10px", height: "180px", width: "100%" }} />}
                              </Col>
                              <Col lg="6" className='sellertour-margin'>
                                <div className='sellertour-title' id='sellertourtitle'>Address</div>
                                <div className='sellertour-text' id='sellertourtext'>{item.property_id !== null && item.property_id.address}</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="3">
                            <Row>
                              <Col lg="6" style={{ marginTop: "15px" }} onClick={() => toggleAgent(item.agent_id)}>
                                {item.agent_id !== null && item.agent_id.img ?
                                  <img alt='agentImage' className='sellertour-img' src={hanldeImage(item.agent_id !== null && item.agent_id.img)} crossOrigin="anonymous" /> : <>{item.agent_id !== null && item.agent_id.name ? <div style={{ width: '88px', height: '88px', marginLeft: 'auto' }} ><AvatarImage fontSize={'38px'} data={item.agent_id !== null && item.agent_id.name ? item.agent_id !== null && item.agent_id.name : ''} /></div>
                                    : null}</>}
                              </Col>
                              <Col lg="6" className='sellertour-margin'>
                                <div className='sellertour-title text-capitalize' id='sellertourtitle'>{item.agent_id !== null && item.agent_id.role}</div>
                                <div className='sellertour-text text-capitalize' id='sellertourtext'>{(item.agent_id !== null && item.agent_id.name)}</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="1" className='sellertour-margin timing'>
                            <div className='sellertour-title' id='sellertourtitle'>Tour Time</div>
                            <div className='sellertour-text' id='sellertourtext'>
                              {/* {console.log("check Tour time", item.date_time.split(":")[0] + ":" + item.date_time.split(":")[1])} */}
                              {/* {(Moment(item.date_time).format('hh:mm A'))} */}
                              {(Moment(item.date_time.split(":")[0] + ":" + item.date_time.split(":")[1]).format('hh:mm A'))}

                            </div>
                          </Col>
                          <Col lg="3" className='sellertour-margin'>
                            <>
                              <Button style={{ width: "50%" }} onClick={() => { setItem(item); toggleUserSuccess() }}
                                className='sellertour-cancel tourCancelseller' variant="primary">Cancel Tour</Button></>
                            <>
                              <Button style={{ width: "50%" }} className='sellertour-chat tourChatseller' variant="primary"
                                onClick={() => { { item.agent_id.online_status === true ? handleChat(item.agent_id._id, item.agent_id.name, item.agent_id.last_name, item.agent_id.img ? hanldeImage(item.agent_id.img) : "") : handleAgentStatus(item) }; ChatData("Listed Property Chat", userDetails, item.agent_id._id, userlocation) }}>
                                <Icon icon="bi:chat" style={{ color: "red" }} />Chat
                              </Button></>
                            <Row className='sellertour-calender touruserCalender' onClick={() => { handleModifyData(item); }}>
                              <img alt='calendar' style={{ width: "45px", height: "20px", marginRight: "-5px" }} src="/images/Icon awesome-calendar-alt.png" />
                              Reschedule Tour
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  ))}
                <>
                  {/* {loader ? <Loader /> : null} */}
                  {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}</>
              </div>
            </div>
          </div>
        </div>
        {isdataFounded && <div className='no-result2' style={{ marginTop: "20%" }}>You do not have any scheduled tours.</div>}
      </Layout>
    </>
  )
}


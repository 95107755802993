import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import ToastPopup from "./ToastPopup";
import { deleteAppoinment, deleteTourTime } from "../Api/ListingApi";
import { useSelector } from "react-redux"

function DeclinePopup(props) {
  const { userSuccess, toggleUserSuccess, success, toggleSuccess, reload, data,back } = props;
  const [messages, setMessages] = useState("")
  const userDetails = useSelector(state => state.user.userDetail)

  const handleDeleteAppoinment = async () => {
   
    if (data.buyer_agent_id && (data.buyer_agent_id._id === userDetails._id)) {
      var getParams = {
         id : data.appointment_id,
        is_deleted_by_buyer_agent: data.buyer_agent_id._id
      }
    } else {
      if (userDetails.role !== "user") {
        var getParams = {
          id : data.appointment_id,
          is_deleted_by: data.agent_id._id
        }
      } else {
        var getParams = {
          id : data.appointment_id,
          is_deleted_by: data.user_id._id
        }
      }
    }
    await deleteTourTime( getParams).then((res) => {
      setMessages(res.data.message)
      if (res.status === 200) {
        toggleSuccess()
        reload("cancel")
        
      }
     
      toggleUserSuccess()
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <div>
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />

      <Modal show={userSuccess} onHide={toggleUserSuccess}>
        <Modal.Header closeButton className="team-popup-margin teampopup-title " >
          <Modal.Title className='form-field-label form-label-team '>Cancel Tour
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0" >
          <div>
            <div className={style1.successtext}>
              <p className={style1.accepttext}>Are you sure you want to cancel?</p>

            </div>
          </div>
          <Button className={style1.nobutton} onClick={toggleUserSuccess}>
            No
          </Button>
          <Button className={style1.yesbutton} onClick={handleDeleteAppoinment}>
            Yes
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default DeclinePopup